*, *:before, *:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: $m-size;
  height: 100%;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

#app {
  height: 100%;
}